import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./header/Header.css";
import { useContext } from "react";
import { ContactContext } from "../context/CreateContext";
import { Fade } from "react-reveal";

const SubmitForm = () => {
  const { addContactReq } = useContext(ContactContext);
  const [userForm, setUserForm] = useState({
    fname: "",
    lname: "",
    email: "",
    mno: "",
    description: "",
  });
  const handleInput = (event) => {
    const key_name = event.target.name;
    const value = event.target.value;
    setUserForm({ ...userForm, [key_name]: value });
  };

  const validation = () => {
    if (userForm.fname.length === 0) {
      return "First name is required";
    }
    if (!/^[a-zA-Z\s]*$/.test(userForm.fname)) {
      return "Enter only text in firstname";
    }
    if (userForm.lname.length === 0) {
      return "Last name is required";
    }
    if (!/^[a-zA-Z\s]*$/.test(userForm.lname)) {
      return "Enter only text in lastname";
    }
    if (userForm.email.length === 0) {
      return "Email is required";
    }
    if (
      !userForm.email.match(
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      return "Please Enter Valid email";
    }
    if (userForm.mno.length === 0) {
      return "Mobile number is required";
    }
    if (userForm.mno.length !== 10) {
      return "Please Enter Minimum 10 Digits Number";
    }
    if (userForm.description.length === 0) {
      return "Description is required";
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const msg = validation(e);
    if (msg) {
      toast.error(`${msg}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      addContactReq(userForm)
      setUserForm({
        fname: "",
        lname: "",
        email: "",
        mno: "",
        description: "",
      });
    }
  };

  return (
    <>
      {/* <ToastContainer /> */}
      <div className="boxed-wrapper">
        <div className="service-one p_relative">
          <div className="auto-container">
            <div className="row clearfix pt_40 mt_40">
              <div className="col-lg-12 col-12">
                <div className="service-block-one">
                  <div className="inner-box p_relative d_block pl_100 pt_100 pb_55 tran_5 m_20 centred rp">
                    <div role="form">
                      <div className="row">
                        <Fade left>
                        <div className="col-lg-5 col-12 mr_51">
                          <input
                            type="text"
                            className="form-control mb_55"
                            autoComplete="off"
                            name="fname"
                            id="firstname"
                            required=""
                            value={userForm.fname}
                            onChange={handleInput}
                            placeholder="Your First Name"
                          />
                        </div>
                        </Fade>
                        <Fade right>
                        <div className="col-lg-5 col-12 ml_51">
                          <input
                            type="text"
                            className="form-control mb_55"
                            autoComplete="off"
                            name="lname"
                            id="lastname"
                            value={userForm.lname}
                            onChange={handleInput}
                            placeholder="Your Last Name"
                          />
                        </div>
                        </Fade>
                        <Fade left>
                        <div className="col-lg-5 col-12 mr_51">
                          <input
                            type="text"
                            className="form-control mb_55"
                            autoComplete="off"
                            name="email"
                            id="email"
                            required=""
                            value={userForm.email}
                            onChange={handleInput}
                            placeholder="Your Email Address"
                          />
                        </div>
                        </Fade>
                        <Fade right>
                        <div className="col-lg-5 col-12 ml_51">
                          <input
                            type="text"
                            className="form-control mb_55"
                            pattern="[0-9]*"
                            autoComplete="off"
                            name="mno"
                            required=""
                            value={userForm.mno}
                            onInput={(e) =>
                              (e.target.value = e.target.value
                                .replace(/[^0-9.]/g, "")
                                .replace(/(\..*?)\..*/g, "$1")
                                .slice(0, 10))
                            }
                            onChange={handleInput}
                            placeholder="Mobile Number"
                          />
                        </div>
                        </Fade>
                        <Fade bottom>
                        <div className="col-lg-11 col-12">
                          <textarea
                            className="form-control mb_70"
                            id="address"
                            name="description"
                            placeholder="Tell Us Something About You"
                            rows="7"
                            value={userForm.description}
                            onChange={handleInput}
                          ></textarea>
                        </div>
                        </Fade>

                        <div className="btn-box col-12 mx-auto mb_20 r_25">
                          <a
                            href={"/"}
                            className="d-inline-flex theme-btn theme-btn-one centred"
                            onClick={handleSubmit}
                          >
                            Submit
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubmitForm;
