import React from "react";
import {
  Routes,
  Route,
  useNavigate,
  Navigate,
} from "react-router-dom";
import About from "./components/About";
import Home from "./components/Home";
import Career from "./components/Career";
import Service from "./components/Service";
import Contact from "./components/Contact";
import Portfolio from "./components/Portfolio";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { ConfigurationContext } from "./context/CreateContext";
import { useEffect } from "react";
import Maintenance from "./components/Maintenance";
import Page404 from "./components/Page404";
import Layout from "./components/Layout";

const App = () => {
  const navigate = useNavigate();
  const { getAllConfiguration, configurationData } =
    useContext(ConfigurationContext);
  useEffect(() => {
    getAllConfiguration();
    if (configurationData && configurationData.mmode === "true") {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <ToastContainer />
      {configurationData && configurationData.mmode !== "false" ? (
        <Routes>
          <Route
            exact
            path="/"
            element={<Maintenance title={"Maintenance"} />}
          ></Route>
          <Route
            exact
            path="*"
            element={<Navigate to="/" replace={true} />}
          ></Route>
        </Routes>
      ) : (
        <Routes>
          <Route
            exact
            path="/"
            element={<Layout Componets={Home} title={"Home"} />}
          ></Route>
          <Route
            path="/about"
            element={<Layout Componets={About} title={"About"} />}
          ></Route>
          <Route
            path="/career"
            element={<Layout Componets={Career} title={"Career"} />}
          ></Route>
          <Route
            path="/service"
            element={<Layout Componets={Service} title={"Service"} />}
          ></Route>
          <Route
            path="/contact"
            element={<Layout Componets={Contact} title={"Contact"} />}
          ></Route>
          <Route
            path="/portfolio"
            element={<Layout Componets={Portfolio} title={"Portfolio"} />}
          ></Route>
          <Route
            path="/demo"
            element={<p>This Is</p>}
          ></Route>
          <Route exact path="*" element={<Page404 title={"404"} />}></Route>
        </Routes>
      )}
      {/* {configurationData && configurationData.mmode === "false" ? (
        <>
          <Header />
          <Routes>
            <Route exact path="/" element={<Home title={"Home"} />}></Route>
            <Route path="/about" element={<About title={"About"} />}></Route>
            <Route
              exact
              path="/career"
              element={<Career title={"Career"} />}
            ></Route>
            <Route
              exact
              path="/service"
              element={<Service title={"Service"} />}
            ></Route>
            <Route
              exact
              path="/contact"
              element={<Contact title={"Contact"} />}
            ></Route>
            <Route
              exact
              path="/portfolio"
              element={<Portfolio title={"Portfolio"} />}
            ></Route>
            <Route exact path="*" element={<Page404 />}></Route>
          </Routes>
          <Footer />
        </>
      ) : (
        <Routes>
          <Route
            exact
            path="/"
            element={<Maintenance title={"Maintenance"} />}
          ></Route>
          <Route
            exact
            path="*"
            element={<Navigate to="/" replace={true} />}
          ></Route>
        </Routes>
      )} */}

      {/* </BrowserRouter> */}
    </>
  );
};
export default App;
