import React from "react";
import "./header/Header.css";
import Content from "./Banner_content";
import { useEffect } from "react";
import { useContext } from "react";
import { ConfigurationContext } from "../context/CreateContext";
import { Fade, Slide } from "react-reveal";

const Service = ({ title }) => {
  const { configurationData } = useContext(ConfigurationContext);

  let cname;

  if (configurationData) {
    cname = `${configurationData.cname}`;
  } else {
    cname = `${process.env.REACT_APP_COMPANY_SITE}`;
  }
  useEffect(() => {
    document.title = `${title} | ${cname} `;
  });
  return (
    <>
      <Content title="Services" pathname="Services" />
      <div className="boxed-wrapper mx-3">
        <div className="p_relative pb_10 mb_10 pt_10 mt_70">
          <div className="auto-container">
            <div className="inner-container p_relative">
              <div className="desk-Content">
                <div className="row align-items-center clearfix mt-5">
                  <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                    <Fade left>
                    <div className="image_block_two">
                      <div className="p_relative d_block">
                        <figure className="image p_relative d_block">
                          <img
                            src={"/assets/images/img/mobile_application.svg"}
                            alt="WEB_CMS_ECOMMERCE"
                            className="web_img "
                          />
                        </figure>
                      </div>
                    </div>
                    </Fade>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                    <div className="mx_px">
                      <div className="content-box p_relative d_block ml_30">
                        <div className="sec-title p_relative d_block mb_20">
                          <Fade right>
                            <h2 className="p_relative d_block fs_40 fw_bold">
                              Mobile Application
                            </h2>
                          </Fade>
                        </div>
                        <Fade right>
                          <div
                            className="p_relative d_block mb_25 wow fadeInUp"
                            data-wow-duration="1500ms"
                          >
                            <p className="fs_18">
                              Mobile application development is essential for
                              businesses of all sizes. Codeash Infotech
                              specializes in custom mobile app development
                              tailored to the specific needs of each business,
                              from native to cross-platform to hybrid enterprise
                              apps.
                            </p>
                          </div>
                        </Fade>
                        <div
                          className="inner-box p_relative d_block wow fadeInUp pt_18"
                          data-wow-duration="1500ms"
                        >
                          <Slide right>
                          <div className="single-item p_relative d_block pl_55 mb_16">
                            <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"/assets/images/img/arrow-right.png"}
                                  alt="ARROW-RIGHT"
                                  className="w_25"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                              We deliver apps with user authentication,
                              notifications, social integration, payments and
                              more.
                            </h4>
                          </div>
                          <div className="single-item p_relative d_block pl_55 mb_16">
                            <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"/assets/images/img/arrow-right.png"}
                                  alt="ARROW-RIGHT"
                                  className="w_25"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                              Expertise in healthcare, fintech, e-commerce and
                              more.
                            </h4>
                          </div>
                          <div className="single-item p_relative d_block pl_55 mb_16">
                            <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"/assets/images/img/arrow-right.png"}
                                  alt="ARROW-RIGHT"
                                  className="w_25"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                              Custom apps for unique business needs.
                            </h4>
                          </div>
                          <div className="single-item p_relative d_block pl_55 mb_16">
                            <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"/assets/images/img/arrow-right.png"}
                                  alt="ARROW-RIGHT"
                                  className="w_25"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                              User-friendly, visually appealing interfaces.
                            </h4>
                          </div>
                          <div className="single-item p_relative d_block pl_55">
                            <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"/assets/images/img/arrow-right.png"}
                                  alt="ARROW-RIGHT"
                                  className="w_25 "
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                              Native (iOS/Android), Flutter, React Native,
                              frontend/backend.
                            </h4>
                          </div>
                          </Slide>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="chooseus-one p_relative pt_10 mt_10">
                  <div className="auto-container">
                    <div className="inner-container p_relative pb_20 mb_10">
                      <div className="row clearfix">
                        <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                          <div className="content-box p_relative d_block z_1 mr-4">
                            <Fade left>
                            <div className="sec-title p_relative d_block pmtb">
                              <h2 className="p_relative d_block fs_35 fw_bold mt_20 pt_50">
                                Web/CMS and E-commerce
                              </h2>
                            </div>
                            </Fade>
                            <Fade left>
                            <div
                              className="p_relative d_block mb_15 wow fadeInUp"
                              data-wow-duration="1500ms"
                            >
                              <p className="fs_18 pt_10">
                                Your website is your storefront on the Internet,
                                and it's important to make a good first
                                impression on potential customers. With a team
                                of experienced web developers, we develop
                                custom, individual websites and e-commerce
                                solutions that meet your needs - from simple to
                                complex.
                              </p>
                            </div>
                            </Fade>
                            <div
                              className="inner-box p_relative d_block wow fadeInUp pt_20"
                              data-wow-duration="1500ms"
                            >
                              <Slide left>
                              <div className="single-item p_relative d_block pl_55 mb_16">
                                <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                  <div className="icon p_relative d_iblock g_color">
                                    <img
                                      src={"/assets/images/img/arrow-right.png"}
                                      alt="ARROW-RIGHT"
                                      className="w_25"
                                    />
                                  </div>
                                </div>
                                <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                  We create responsive websites for all devices.
                                </h4>
                              </div>
                              <div className="single-item p_relative d_block pl_55 mb_16">
                                <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                  <div className="icon p_relative d_iblock g_color">
                                    <img
                                      src={"/assets/images/img/arrow-right.png"}
                                      alt="ARROW-RIGHT"
                                      className="w_25"
                                    />
                                  </div>
                                </div>
                                <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                  Fast loading, smooth user experience
                                  guaranteed.
                                </h4>
                              </div>
                              <div className="single-item p_relative d_block pl_55 mb_16">
                                <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                  <div className="icon p_relative d_iblock g_color">
                                    <img
                                      src={"/assets/images/img/arrow-right.png"}
                                      alt="ARROW-RIGHT"
                                      className="w_25"
                                    />
                                  </div>
                                </div>
                                <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                  Cybersecurity measures to protect your
                                  website.
                                </h4>
                              </div>
                              <div className="single-item p_relative d_block pl_55 mb_16">
                                <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                                  <div className="icon p_relative d_iblock g_color">
                                    <img
                                      src={"/assets/images/img/arrow-right.png"}
                                      alt="ARROW-RIGHT"
                                      className="w_25"
                                    />
                                  </div>
                                </div>
                                <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                                  Improve your website's search engine rankings.
                                </h4>
                              </div>
                              </Slide>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                          <Fade right>
                          <div className="image_block_two">
                            <div className="image-box p_relative d_block">
                              <figure className="image p_relative d_block">
                                <img
                                  src={
                                    "/assets/images/img/web_cms_ecommerce.svg"
                                  }
                                  alt="MOBILE_APPLICATION"
                                  className="mob_img mt-5 pt-5"
                                />
                              </figure>
                            </div>
                          </div>
                          </Fade>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center clearfix mt-5">
                  <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                    <Fade left>
                    <div className="image_block_two">
                      <div className="p_relative d_block">
                        <figure className="image p_relative d_block">
                          <img
                            src={"/assets/images/img/graphic_designing.svg"}
                            alt="WEB_CMS_ECOMMERCE"
                            className="web_img "
                          />
                        </figure>
                      </div>
                    </div>
                    </Fade>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                    <div className="mx_px">
                      <div className="content-box p_relative d_block ml_30">
                        <Fade right>
                        <div className="sec-title p_relative d_block mb_20">
                          <h2 className="p_relative d_block fs_40 fw_bold">
                            Graphics Designing
                          </h2>
                        </div>
                        </Fade>
                        <Fade right>
                        <div
                          className="p_relative d_block mb_25 wow fadeInUp"
                          data-wow-duration="1500ms"
                        >

                          <p className="fs_18">
                            Well-designed graphics can help you attract
                            attention, communicate your message effectively and
                            build a strong brand identity. We design and help
                            you create logos, branding and identity materials,
                            graphics for social media, websites and landing
                            pages, and much more.
                          </p>
                        </div>
                          </Fade>
                        <div
                          className="inner-box p_relative d_block wow fadeInUp pt_10"
                          data-wow-duration="1500ms"
                        >
                          <Slide right>
                         
                          <div className="single-item p_relative d_block pl_55 mb_16">
                            <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"/assets/images/img/arrow-right.png"}
                                  alt="ARROW-RIGHT"
                                  className="w_25"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                              Captivating infographics to effectively convey
                              your message.
                            </h4>
                          </div>
                          <div className="single-item p_relative d_block pl_55 mb_16">
                            <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"/assets/images/img/arrow-right.png"}
                                  alt="ARROW-RIGHT"
                                  className="w_25"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                              Unique illustrations to make your brand stand out.
                            </h4>
                          </div>
                          <div className="single-item p_relative d_block pl_55 mb_16">
                            <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"/assets/images/img/arrow-right.png"}
                                  alt="ARROW-RIGHT"
                                  className="w_25"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                              Clear data visualizations for better
                              understanding.
                            </h4>
                          </div>
                          <div className="single-item p_relative d_block pl_55">
                            <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"/assets/images/img/arrow-right.png"}
                                  alt="ARROW-RIGHT"
                                  className="w_25 "
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                              Custom icons for a better user experience.
                            </h4>
                          </div>
                          </Slide>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="phone-Content">
                <div className="row align-items-center clearfix">
                  <div className="col-lg-6 col-md-12 col-sm-12 content-column mt-5">
                    <div className="">
                      <div className="content-box p_relative d_block">
                        <div className="sec-title p_relative d_block">
                          <Fade>
                          <h2 className="p_relative d_block fs_40 fw_bold pb_10">
                            Mobile Application
                          </h2>
                          </Fade>
                        </div>
                        <div
                          className="p_relative d_block mb_25 wow fadeInUp"
                          data-wow-duration="1500ms"
                        >
                          <Fade>

                          <p className="fs_18">
                            Mobile application development is essential for
                            businesses of all sizes. Codeash Infotech
                            specializes in custom mobile app development
                            tailored to the specific needs of each business,
                            from native to cross-platform to hybrid enterprise
                            apps.
                          </p>
                          </Fade>
                        </div>
                        <div
                          className="inner-box p_relative d_block wow fadeInUp pt_20"
                          data-wow-duration="1500ms"
                        >
                          <Slide left>
                          <div className="single-item p_relative d_block pl_55 mb_16">
                            <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"/assets/images/img/arrow-right.png"}
                                  alt="ARROW-RIGHT"
                                  className="w_25"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                              We deliver apps with user authentication,
                              notifications, social integration, payments and
                              more.
                            </h4>
                          </div>
                          <div className="single-item p_relative d_block pl_55 mb_16">
                            <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"/assets/images/img/arrow-right.png"}
                                  alt="ARROW-RIGHT"
                                  className="w_25"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                              Expertise in healthcare, fintech, e-commerce and
                              more.
                            </h4>
                          </div>
                          <div className="single-item p_relative d_block pl_55 mb_16">
                            <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"/assets/images/img/arrow-right.png"}
                                  alt="ARROW-RIGHT"
                                  className="w_25"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                              Custom apps for unique business needs.
                            </h4>
                          </div>
                          <div className="single-item p_relative d_block pl_55 mb_16">
                            <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"/assets/images/img/arrow-right.png"}
                                  alt="ARROW-RIGHT"
                                  className="w_25"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                              User-friendly, visually appealing interfaces.
                            </h4>
                          </div>
                          <div className="single-item p_relative d_block pl_55">
                            <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"/assets/images/img/arrow-right.png"}
                                  alt="ARROW-RIGHT"
                                  className="w_25"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                              Native (iOS/Android), Flutter, React Native,
                              frontend/backend.
                            </h4>
                          </div>
                          </Slide>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                    <Fade>
                    <div className="image_block_two">
                      <div className="p_relative d_block">
                        <figure className="image p_relative d_block mb-5">
                          <img
                            src={"/assets/images/img/mobile_application.svg"}
                            alt="WEB_CMS_ECOMMERCE"
                            className="web_img"
                            />
                        </figure>
                      </div>
                    </div>
                            </Fade>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 content-column mt-5 pt-5">
                    <div className="">
                      <div className="content-box p_relative d_block">
                        <div className="sec-title p_relative d_block">
                          <Fade>
                          <h2 className="p_relative d_block fs_40 fw_bold pb_10">
                            Web/CMS&Ecommerce
                          </h2>
                          </Fade>
                        </div>
                        <div
                          className="p_relative d_block mb_25 wow fadeInUp"
                          data-wow-duration="1500ms"
                        >
                          <Fade>

                          <p className="fs_18">
                            Your website is your storefront on the Internet, and
                            it's important to make a good first impression on
                            potential customers. With a team of experienced web
                            developers, we develop custom, individual websites
                            and e-commerce solutions that meet your needs - from
                            simple to complex.
                          </p>
                          </Fade>
                        </div>
                        <div
                          className="inner-box p_relative d_block wow fadeInUp pt_20"
                          data-wow-duration="1500ms"
                        >
                          <Slide left>
                          <div className="single-item p_relative d_block pl_55 mb_16">
                            <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"/assets/images/img/arrow-right.png"}
                                  alt="ARROW-RIGHT"
                                  className="w_25"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                              We create responsive websites for all devices.
                            </h4>
                          </div>
                          <div className="single-item p_relative d_block pl_55 mb_16">
                            <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"/assets/images/img/arrow-right.png"}
                                  alt="ARROW-RIGHT"
                                  className="w_25"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                              Fast loading, smooth user experience guaranteed.
                            </h4>
                          </div>
                          <div className="single-item p_relative d_block pl_55 mb_16">
                            <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"/assets/images/img/arrow-right.png"}
                                  alt="ARROW-RIGHT"
                                  className="w_25"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                              Cybersecurity measures to protect your website.
                            </h4>
                          </div>
                          <div className="single-item p_relative d_block pl_55">
                            <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"/assets/images/img/arrow-right.png"}
                                  alt="ARROW-RIGHT"
                                  className="w_25"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                              Improve your website's search engine rankings.
                            </h4>
                          </div>
                          
                          </Slide>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                    <Fade>
                    <div className="image_block_two">
                      <div className="p_relative d_block">
                        <figure className="image p_relative d_block mb-5">
                          <img
                            src={"/assets/images/img/web_cms_ecommerce.png"}
                            alt="WEB_CMS_ECOMMERCE"
                            className="web_img"
                          />
                        </figure>
                      </div>
                    </div>
                    </Fade>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 content-column mt-5 pt-5">
                    <div className="">
                      <div className="content-box p_relative d_block">
                        <div className="sec-title p_relative d_block">
                          <Fade>
                          <h2 className="p_relative d_block fs_40 fw_bold pb_10">
                            Graphics Designing
                          </h2>
                          </Fade>
                        </div>
                        <div
                          className="p_relative d_block mb_25 wow fadeInUp"
                          data-wow-duration="1500ms"
                        >
                          <Fade>

                          <p className="fs_18">
                            Well-designed graphics can help you attract
                            attention, communicate your message effectively and
                            build a strong brand identity. We design and help
                            you create logos, branding and identity materials,
                            graphics for social media, websites and landing
                            pages, and much more.
                          </p>
                          </Fade>
                        </div>
                        <div
                          className="inner-box p_relative d_block wow fadeInUp pt_20"
                          data-wow-duration="1500ms"
                        >
                          <Slide left>
                          <div className="single-item p_relative d_block pl_55 mb_16">
                            <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"/assets/images/img/arrow-right.png"}
                                  alt="ARROW-RIGHT"
                                  className="w_25"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                              Captivating infographics to effectively convey
                              your message.
                            </h4>
                          </div>
                          <div className="single-item p_relative d_block pl_55 mb_16">
                            <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"/assets/images/img/arrow-right.png"}
                                  alt="ARROW-RIGHT"
                                  className="w_25"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                              Unique illustrations to make your brand stand out.
                            </h4>
                          </div>
                          <div className="single-item p_relative d_block pl_55 mb_16">
                            <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"/assets/images/img/arrow-right.png"}
                                  alt="ARROW-RIGHT"
                                  className="w_25"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                              Clear data visualizations for better
                              understanding.
                            </h4>
                          </div>
                          <div className="single-item p_relative d_block pl_55">
                            <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"/assets/images/img/arrow-right.png"}
                                  alt="ARROW-RIGHT"
                                  className="w_25"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                              Custom icons for a better user experience.
                            </h4>
                          </div>
                          </Slide>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                    <Fade>
                    <div className="image_block_two">
                      <div className="p_relative d_block">
                        <figure className="image p_relative d_block mb-5">
                          <img
                            src={"/assets/images/img/graphic_designing.png"}
                            alt="WEB_CMS_ECOMMERCE"
                            className="web_img"
                          />
                        </figure>
                      </div>
                    </div>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="chooseus-one p_relative pmb">
          <div className="auto-container">
            <div className="inner-container p_relative">
              <div className="row clearfix">
                <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                  <div className="mr_100">
                    <div className="content-box p_relative d_block">
                      <div className="sec-title p_relative d_block pt_130 mb_20 mt_50">
                        <h2 className="p_relative d_block fs_40 fw_bold mb_0">
                          Graphic Designing
                        </h2>
                      </div>
                      <div
                        className="p_relative d_block mb_25 wow fadeInUp"
                        data-wow-duration="1500ms"
                      >
                        <p className="fs_19">
                        Well-designed graphics can help you attract attention, communicate your message effectively and build a strong brand identity. We design and help you create logos, branding and identity materials, graphics for social media, websites and landing pages, and much more.
                        </p>
                      </div>
                      <div
                        className="inner-box p_relative d_block wow fadeInUp"
                        data-wow-duration="1500ms"
                      >
                        <div className="single-item p_relative d_block pl_55 mb_16">
                          <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                            <div className="icon p_relative d_iblock g_color">
                              <img
                                src={"/assets/images/img/arrow-right.png"}
                                alt="ARROW-RIGHT"
                                className="w_25"
                              />
                            </div>
                          </div>
                          <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                          Captivating infographics to effectively convey your message.
                          </h4>
                        </div>
                        <div className="single-item p_relative d_block pl_55 mb_16">
                          <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                            <div className="icon p_relative d_iblock g_color">
                              <img
                                src={"/assets/images/img/arrow-right.png"}
                                alt="ARROW-RIGHT"
                                className="w_25"
                              />
                            </div>
                          </div>
                          <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                          Unique illustrations to make your brand stand out.
                          </h4>
                        </div>
                        <div className="single-item p_relative d_block pl_55 mb_16">
                          <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                            <div className="icon p_relative d_iblock g_color">
                              <img
                                src={"/assets/images/img/arrow-right.png"}
                                alt="ARROW-RIGHT"
                                className="w_25"
                              />
                            </div>
                          </div>
                          <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                          Clear data visualizations for better understanding.
                          </h4>
                        </div>
                        <div className="single-item p_relative d_block pl_55">
                          <div className="icon-box p_absolute l_0 t_6 w_50 h_50 text-center">
                            <div className="icon p_relative d_iblock g_color">
                              <img
                                src={"/assets/images/img/arrow-right.png"}
                                alt="ARROW-RIGHT"
                                className="w_25"
                              />
                            </div>
                          </div>
                          <h4 className="d_block fs_20 lh_30 mb_4 fw_sbold">
                          Custom icons for a better user experience.
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                  <div className="image_block_two">
                    <div className="image-box p_relative d_block">
                      <figure className="image p_relative d_block">
                        <img
                          src={"/assets/images/img/graphic_designing.png"}
                          alt="GRAPHIC_DESIGNING"
                          className="gra_img"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Service;
