import React from "react";
import "./header/Header.css";
import Content from "./Banner_content";
import { useEffect } from "react";
import { useContext } from "react";
import { ConfigurationContext } from "../context/CreateContext";
import SubmitForm from "./Form";
import { Fade } from "react-reveal";
import Jello from "react-reveal/Jello";

const Contact = ({ title }) => {
  // const HR_MAIL = process.env.REACT_APP_HR_MAIL;
  // const ADMIN_MAIL = process.env.REACT_APP_ADMIN_MAIL;
  // const ADMIN_NUMBER = process.env.REACT_APP_ADMIN_NUMBER;
  // const COMPANY_ADDRESS = process.env.REACT_APP_ADDRESS;
  // // const TEL = process.env.REACT_APP_TEL;
  // const HR_MAILTO = process.env.REACT_APP_MAILTO;

  const { configurationData } = useContext(ConfigurationContext);

  let HR_MAIL;
  let ADMIN_MAIL;
  let ADMIN_NUMBER;
  let COMPANY_ADDRESS;
  let HR_MAILTO;
  let cname;

  if (configurationData) {
    HR_MAIL = `${configurationData.hrmail}`;
    ADMIN_MAIL = `${configurationData.amail}`;
    ADMIN_NUMBER = `${configurationData.anumber}`;
    COMPANY_ADDRESS = `${configurationData.address}`;
    HR_MAILTO = `mailto:${configurationData.hrmail}`;
    cname = `${configurationData.cname}`;
  } else {
    HR_MAIL = `${process.env.REACT_APP_HR_MAIL}`;
    HR_MAILTO = `mailto:${process.env.REACT_APP_HR_MAIL}`;
    ADMIN_MAIL = `${process.env.REACT_APP_ADMIN_MAIL}`;
    ADMIN_MAIL = `${process.env.REACT_APP_ADMIN_NUMBER}`;
    COMPANY_ADDRESS = `${process.env.REACT_APP_ADDRESS}`;
    cname = `${process.env.REACT_APP_COMPANY_SITE}`;
  }
  useEffect(() => {
    document.title = `${title} | ${cname} `;
  });

  return (
    <>
      <Content title="Contact" pathname="Contact" />
      <div className="boxed-wrapper">
        <div className="service-one p_relative pt_50 mt_30">
          <div className="auto-container">
            <Fade top>
            <div className="sec-title p_relative d_block mb_50 mt_25 text-center">
              <h2 className="p_relative d_block fs_40 fw_bold mb_20">
                Get In Touch!
              </h2>
              <p className="d_block fs_17">
                Do you need a Digital BOOST? For more information about our
                services and how we can help you achieve your goals, contact us
                now.
                <br /> PS: We offer a free consultation to discuss your needs
              </p>
            </div>
            </Fade>
            <SubmitForm />
            <div className="row clearfix pt_30 justify-content-md-center ml-4 ml-lg-0">
              <Jello>
              <div className="col-lg-4 col-md-7 col-sm-12 service-block">
                <div className="service-block-one wow mx-lg-4">
                  <div className="inner-box p_relative d_block tran_5 icon_box">
                    <div className="icon-box p_relative">
                      <div className="p_relative d_iblock g_color tran_5 icon_svg">
                        <img src={"/assets/images/icon/mail.svg"} alt="MAIL" />
                      </div>
                    </div>
                    <p className="content_a icon_mail">
                      <a href={HR_MAILTO}> {HR_MAIL}</a>
                      <a href={HR_MAILTO}> {ADMIN_MAIL}</a>
                    </p>
                  </div>
                </div>
              </div>
              </Jello>
              <Jello>
              <div className="col-lg-4 col-md-7 col-sm-12 service-block">
                <div className="service-block-one wow mx-lg-4">
                  <div className="inner-box p_relative d_block tran_5 icon_box ml_40 marl ml-0">
                    <div className="icon-box p_relative">
                      <div className="p_relative d_iblock g_color tran_5 icon_svg">
                        <img
                          src={"/assets/images/icon/whatsapp.svg"}
                          alt="WHATSAPP"
                        />
                      </div>
                    </div>
                    <p className="content_a icon_mail">
                      <a href={`tel:${ADMIN_NUMBER}`}> {ADMIN_NUMBER} </a>
                    </p>
                  </div>
                </div>
              </div>
              </Jello>
              <Jello>
              <div className="col-lg-4 col-md-7 col-sm-12 service-block">
                <div className="service-block-one wow mx-lg-4">
                  <div className="inner-box p_relative d_block tran_5 icon_box ml_50 marl ml-0">
                    <div className="icon-box p_relative">
                      <div className="p_relative d_iblock g_color tran_5 icon_svg">
                        <img
                          src={"/assets/images/icon/address.svg"}
                          alt="ADDRESS"
                        />
                      </div>
                    </div>
                    <p className="content_a icon_mail">
                      <a href={"/"}> {COMPANY_ADDRESS} </a>
                    </p>
                  </div>
                </div>
              </div>
              </Jello>
            </div>
          </div>
        </div>
        <Fade>
        <div className="pt_15 map lh_14">
          {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.440433258946!2d72.83763821540418!3d21.21437668679046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f512c17f8a3%3A0xe3dc01498584ea95!2sCodeash%20Infotech!5e0!3m2!1sen!2sin!4v1676984601031!5m2!1sen!2sin"
                 width="600" 
                 height="450" 
                 style={{border:0}}
                 allowFullScreen="" 
                 loading="lazy" 
                 referrerPolicy="no-referrer-when-downgrade"></iframe> */}
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.440433258937!2d72.83763821540418!3d21.21437668679046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f512c17f8a3%3A0xe3dc01498584ea95!2sCodeash%20Infotech!5e0!3m2!1sen!2sin!4v1676454922243!5m2!1sen!2sin"
            width="100%"
            height="340"
            title="map"
            style={{ border: "0" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        </Fade>
      </div>
    </>
  );
};

export default Contact;
