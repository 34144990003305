import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./header/Header.css";
// import "react-multi-carousel/lib/styles.css";
// import logo from "../assets/image/icon/Android.png";
// import  Carousel from "react-multi-carousel";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { ConfigurationContext, ReviewContext } from "../context/CreateContext";
import { useEffect } from "react";
import ClientLogo from "../components/Client_logo";
// import { InView, useInView } from "react-intersection-observer";
import { Fade } from "react-reveal";
import Jello from "react-reveal/Jello";
import CountUp from "react-countup";
// import { motion, useScroll } from "framer-motion";

const Home = ({ title }) => {
  // const PublicImageURL = process.env.REACT_APP_PUBLIC_IMAGE_URL
  const { configurationData } = useContext(ConfigurationContext);

  let PublicImageURL;
  let cname;

  if (configurationData) {
    PublicImageURL = `${configurationData.surl}/img`;
    cname = `${configurationData.cname}`;
  } else {
    PublicImageURL = `${process.env.REACT_APP_SERVER_API_URL}/img`;
    cname = `${process.env.REACT_APP_COMPANY_SITE}`;
  }
  const { getAllReview, reviewData } = useContext(ReviewContext);

  useEffect(() => {
    document.title = `${title} | ${cname} `;
    getAllReview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Animation Code
  // const { scrollYProgress } = useScroll();

  return (
    <>
      {/* <motion.div
        style={{
          scaleX: scrollYProgress,
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          height: "10px",
          background: "red",
          transformOrigin: "0%",
        }}
      /> */}
      <div className="boxed-wrapper">
        <header className="main-header">
          <div className="header-top-one p_relative d_block border-bottom">
            <div className="">
              <img src={"/assets/images/banner/Banner-1.jpg"} width={"100"} height={100} alt="BANNER-1" />
            </div>
            <div className="auto-container">
              <Fade left>
                <div className="col-lg-6 col-12 main_banner p_relative">
                  <div>
                    <h5 className="_h5">Learn More About Codeash Infotech</h5>
                    <h1 className="mb-5 _h1">
                      Leading Web & Mobile <br /> App Development
                    </h1>
                    <div className="btn-box pt_10">
                      <Link
                        to={"/about"}
                        className="theme-btn theme-btn-one button _btn"
                      >
                        About More
                      </Link>
                    </div>
                  </div>
                </div>
              </Fade>
              <Fade top >
                <div
                  className="sec-title p_relative d_block mt_60 pt_55 text-center"
                  id="box1"
                >
                  <h2 className="p_relative d_block fs_42 fw_bold mb_18 ">
                    Who We Are?
                  </h2>
                  <p className="d_block">
                    We are your trusted and reliable partner for all your app
                    and web development needs,
                    <br /> from concept to launch and beyond.
                  </p>
                </div>
              </Fade>

              <div className="inner-container sec-pad p_relative mb_36">
                <div className="row align-items-center clearfix ">
                  <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                    <div className="image_block_three">
                      <Fade left>
                        <div className="image-box d_block">
                          <figure className="image p_relative d_block">
                            <img
                              src={"/assets/images/img/who_we_are_Image.svg"}
                              alt="WHO_WE_ARE_IMAGE"
                              className="w-85 ml-4"
                            />
                          </figure>
                        </div>
                      </Fade>
                    </div>
                  </div>
                  <Fade right cascade>
                    <div className="col-lg-6 col-md-12 col-sm-12 content-column animate__backInRight">
                      {/* <div className="col-lg-6 col-md-12 col-sm-12 content-column" id="box4"> */}
                      <div className="content_block_three">
                        <div className="p_relative d_block">
                          <div className="sec-title mx-3 p_relative d_block">
                            <ul>
                              <li>
                                <h5 className="p_relative d_block fw_sbold mb-4">
                                  Have a digital idea you want to bring to life?
                                  Codeash Infotech is the team to help you make
                                  it happen.
                                </h5>
                              </li>
                            </ul>
                          </div>
                          <div className="p_relative d_block mb_30 wow">
                            <p className="_pb fs_18 lh_30">
                              We are a leading IT company specializing in mobile
                              and website development. We have a team of
                              experienced and skilled developers who can create
                              customized solutions for your unique needs.
                            </p>
                            <br />
                            <p className="_pb fs_18 lh_30">
                              We believe that every business is unique. That's
                              why we take the time to understand your specific
                              needs and goals before starting a project. We work
                              with you to develop custom software that is both
                              functional and visually appealing.
                            </p>
                            <br />
                            <p className="_pb fs_18 lh_30">
                              Whether you are a startup or a Fortune 500
                              company, we have the skills and experience to help
                              you achieve your digital goals. We are committed
                              to delivering high-quality projects on time and on
                              budget.
                            </p>
                            <br />
                            <p className="_pb fs_18 lh_30">
                              Keep scrolling to learn more about our services
                              and clients.
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  </Fade>
                </div>
              </div>
            </div>

            {/* -------------------- */}

            <div className="funfact-one pt_148 pb_148 text-center p_relative">
              <div>
                <div className="p_absolute"></div>
              </div>
              <div className="auto-container">
                <div className="row clearfix">
                  <div className="col-lg-3 col-md-6 col-sm-12 funfact-block">
                    <div className="counter-block-one wow">
                      <div className="inner-box p_relative d_block b_11">
                        <div className="count-outer count-box p_relative d_block fs_42 lh_70 g_color fw_exbold pb_5">
                          {/* <span className="count-text">300+</span> */}
                          <CountUp end={300} enableScrollSpy={true} />+
                        </div>
                        <p className="p_relative d_block fs_19 lh_20 fw_sbold g_color">
                          Our Satisfied Clients{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 funfact-block">
                    <div className="counter-block-one wow">
                      <div className="inner-box p_relative d_block b_11">
                        <div className="count-outer count-box p_relative d_block fs_42 lh_70 g_color fw_exbold pb_5">
                          {/* <span className="count-text">465</span> */}
                          <CountUp end={465} enableScrollSpy={true} />+
                        </div>
                        <p className="p_relative d_block fs_19 lh_20 fw_sbold g_color">
                          Project Completed{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 funfact-block">
                    <div className="counter-block-one wow">
                      <div className="inner-box p_relative d_block b_11">
                        <div className="count-outer count-box p_relative d_block fs_42 lh_70 g_color fw_exbold pb_5">
                          {/* <span className="count-text">9000+</span> */}
                          <CountUp end={9000} enableScrollSpy={true} />+
                        </div>
                        <p className="p_relative d_block fs_19 lh_20 fw_sbold g_color">
                          Total Working Hour{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 funfact-block">
                    <div className="counter-block-one wow">
                      <div className="inner-box p_relative d_block b_11">
                        <div className="count-outer count-box p_relative d_block fs_42 lh_70 g_color fw_exbold pb_5">
                          {/* <span className="count-text">36</span> */}
                          <CountUp end={36} enableScrollSpy={true} />
                        </div>
                        <p className="p_relative d_block fs_19 lh_20 fw_sbold g_color">
                          Awarded Winning
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* ------------------ */}

            <div className="service-one p_relative sec-pad">
              <div className="auto-container r1">
                {/* <motion.div
                  initial={box2.initial}
                  animate={box2.controls}
                  transition={box2.transition}
                > */}
                <Fade top>
                  <div
                    className="p_relative d_block mb_50 mt-4 pb-4 text-center"
                    id="box2"
                  >
                    <h2 className="p_relative d_block fs_42">Our Services</h2>
                    <p className="d_block fs_17 mt-4">
                      Our services are designed to provide the best possible
                      solutions for our clients.
                    </p>
                  </div>
                </Fade>
                {/* </motion.div> */}
                <div className="container-fluid">
                  <div className="row clearfix pt_30 pb-4 justify-content-md-center ">
                    <Jello>
                      <div className="col-lg-5 col-xl-4 col-md-7 col-sm-12 service-block rmx">
                        <div className="service-block-one wow">
                          <div className="inner-box p_relative d_block tran_5 ml_50 mr_50">
                            <div className="p_relative d_iblock mb_30 box-card">
                              <div className="p_relative d_iblock tran_5 i_svg">
                                <img
                                  src={
                                    "/assets/images/icon/mobile_application_new.svg"
                                  }
                                  alt="MOBILE_APPLICATION"
                                />
                              </div>
                              <h4 className="p_relative d_block fs_25 lh_30 fw_sbold mb_30 mt_60 pt_80 text-center">
                                <Link to={"/"}>Mobile Application</Link>
                              </h4>
                              <p className="p_relative d_block fs_18 text-center pl_25 pr_25">
                                {/* {showAll ? string.slice(0, 90) : string}
                              {string.length > 90 && (
                                <span onClick={toggleReadMore}>
                                  {showAll ? "..." : " ..."}
                                </span>
                              )} */}
                                We develop native mobile apps for Android and
                                iOS that are fast, user-friendly and tailored to
                                your specific needs.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Jello>
                    <Jello>
                      <div className="col-lg-5 col-md-7 col-xl-4 col-sm-12 service-block rmx">
                        <div className="service-block-one wow">
                          <div className="inner-box p_relative d_block tran_5 ml_50 mr_50">
                            <div className="p_relative d_iblock mb_30 box-card">
                              <div className="p_relative d_iblock tran_5 i_svg">
                                <img
                                  src={
                                    "/assets/images/icon/web_cms_ecommerce.svg"
                                  }
                                  alt="WEB_CMS_ECOMMERCE"
                                />
                              </div>
                              <h4 className="p_relative d_block fs_25 lh_30 fw_sbold mb_30 mt_60 pt_80 text-center">
                                <Link to={"/"}>Web/CMS & Ecommerce</Link>
                              </h4>
                              <p className="p_relative d_block fs_18 text-center pl_25 pr_25">
                                {/* {showAll ? string1.slice(0, 90) : string1}
                              {string1.length > 90 && (
                                <span onClick={toggleReadMore}>
                                  {showAll ? "..." : " ..."}
                                </span>
                              )} */}
                                Our team of experts has in-depth knowledge of
                                the latest web development technologies, so we
                                can create a website that is both visually
                                appealing and functional.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Jello>
                    <Jello>
                      <div className="col-lg-5 col-md-7 col-sm-12 col-xl-4 service-block rmx">
                        <div className="service-block-one wow">
                          <div className="inner-box p_relative d_block tran_5 ml_50 mr_50">
                            <div className="p_relative d_iblock mb_30 box-card">
                              <div className="p_relative d_iblock tran_5 i_svg">
                                <img
                                  src={
                                    "/assets/images/icon/graphics_designing.svg"
                                  }
                                  alt="GRAPHICS_DESIGNING"
                                />
                              </div>
                              <h4 className="p_relative d_block fs_25 lh_30 fw_sbold mb_30 mt_60 pt_80 text-center">
                                <Link to={"/"}>Graphics Designing</Link>
                              </h4>
                              <p className="p_relative d_block fs_18 text-center pl_25 pr_25">
                                {/* {showAll ? string2.slice(0, 90) : string2}
                              {string2.length > 90 && (
                                <span onClick={toggleReadMore}>
                                  {showAll ? "..." : " ..."}
                                </span>
                              )} */}
                                From logos to social media graphics, we can
                                create custom graphics that make your brand
                                stand out.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Jello>
                  </div>
                </div>
              </div>
            </div>
            <div className="cta-one p_relative pt_130 pb_140 centred ">
              <div className="bg-layer p_absolute l_0 parallax_none parallax-bg get_img"></div>
              <div className="auto-container">
                <div className="inner-box p_relative z_1">
                  <Fade right>
                    <h2 className="d_block fs_40 fw_bold mb_20">
                      Get In Touch
                    </h2>
                  </Fade>
                  <Fade>
                    <p className="fs_16 mb_30">
                      Do you need a Digital BOOST? For more information about
                      our services and how we can help you achieve your goals,
                      contact us now. <br />
                      PS: We offer a free consultation to discuss your needs
                    </p>
                  </Fade>
                  <Fade right cascade>
                    <h6 className="mb_30 fs_20 fw_sbold mb-lg-5">
                      " Say Hello! Don't Be Shy. "
                    </h6>
                  </Fade>
                  <Link
                    to="/contact"
                    className="theme-btn theme-btn-one con_btn"
                  >
                    <span data-text="Contact Us">Contact Us</span>
                  </Link>
                </div>
              </div>
            </div>

            {reviewData && reviewData.length !== 0 ? (
              <div>
                {/* <motion.div
                  initial={box3.initial}
                  animate={box3.controls}
                  transition={box3.transition}
                > */}
                <Fade top>
                  <div
                    className="sec-title p_relative d_block mb_35 text-center z_1 pb_10"
                    id="box3"
                  >
                    <h2 className="p_relative d_block fs_42 fw_bold mb_10 pt_130">
                      Our Clients Reviews
                    </h2>
                    <p>
                      I have a remote team that I can't buy a beer for in
                      person. This is an awesome way to
                      <br /> send my appreciation and build a cohesive company
                      culture with my staff!
                    </p>
                  </div>
                </Fade>
                {/* </motion.div> */}

                <Carousel
                  showArrows={true}
                  infiniteLoop={true}
                  showThumbs={false}
                  showStatus={false}
                  autoPlay={true}
                  interval={2000}
                >
                  {reviewData &&
                    reviewData.map(
                      ({ c_name, c_rating, c_review, avatar }, i) => {
                        return (
                          <div className="mb_50 pb_40" key={i}>
                            <img
                              src={`${PublicImageURL}/client/${avatar}`}
                              alt="SLIDE_1"
                            />
                            <div className="myCarousel">
                              <h3 className="mt-3">{c_name}</h3>
                              <ul className="list-unstyled d-flex justify-content-center text-warning fs_19 mt-4">
                                <li className="pr_3">
                                  {
                                    <i
                                      className={`fas fa-star fa-sm ${
                                        1 <= c_rating ? "fill" : ""
                                      }`}
                                    ></i>
                                  }
                                </li>
                                <li className="pr_3">
                                  {
                                    <i
                                      className={`fas fa-star fa-sm ${
                                        2 <= c_rating ? "fill" : ""
                                      }`}
                                    ></i>
                                  }
                                </li>
                                <li className="pr_3">
                                  {
                                    <i
                                      className={`fas fa-star fa-sm ${
                                        3 <= c_rating ? "fill" : ""
                                      }`}
                                    ></i>
                                  }
                                </li>
                                <li className="pr_3">
                                  {
                                    <i
                                      className={`fas fa-star fa-sm ${
                                        4 <= c_rating ? "fill" : ""
                                      }`}
                                    ></i>
                                  }
                                </li>
                                <li className="pr_3">
                                  {
                                    <i
                                      className={`fas fa-star fa-sm ${
                                        5 <= c_rating ? "fill" : ""
                                      }`}
                                    ></i>
                                  }
                                </li>
                              </ul>
                              <p>"{c_review}"</p>
                            </div>
                          </div>
                        );
                      }
                    )}
                </Carousel>
              </div>
            ) : (
              <h4 className="text-center mt-5">Not Active Any Review</h4>
            )}
            <ClientLogo />
          </div>
        </header>
      </div>
    </>
  );
};

export default Home;
