import React from "react";
import "./header/Header.css";
import Content from "./Banner_content";
import { useEffect } from "react";
import { useContext } from "react";
import { ConfigurationContext } from "../context/CreateContext";
import ClientLogo from "../components/Client_logo";
import { Fade, Slide } from "react-reveal";

const About = ({ title }) => {
  const { configurationData } = useContext(ConfigurationContext);

  let cname;

  if (configurationData) {
    cname = `${configurationData.cname}`;
  } else {
    cname = `${process.env.REACT_APP_COMPANY_SITE}`;
  }

  useEffect(() => {
    document.title = `${title} | ${cname} `;
  });

  return (
    <>
      <Content title="About Codeash" pathname="About Us" />

      <div className="boxed-wrapper">
        {/* <div className="chooseus-one p_relative">
          <div className="auto-container">
            <div className="inner-container p_relative pb_20 mb_10 imgmp_top">
              
            </div>
          </div>
        </div> */}

        {/* Service Responsive Pending */}

        <div className="deskContent">
          <div className="p_relative pb_50 mb_50">
            <div className="auto-container">
              <div className="inner-container p_relative mt-5 mx-4">
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                    <div className="">
                      <div className="content-box p_relative d_block z_1">
                        <Fade left>
                          <div className="sec-title p_relative d_block pt_130 mb_20">
                            <h2 className="p_relative d_block fs_40 fw_bold mt_10">
                              About Our Company
                            </h2>
                          </div>
                        </Fade>
                        <Fade left>
                          <div
                            className="p_relative d_block mb_25 wow fadeInUp"
                            data-wow-duration="1500ms"
                          >
                            <p className="fs_19">
                              Imagine a world where your business runs smoothly
                              and efficiently, with software and applications
                              tailored to your specific needs. That's the world
                              Codeash Infotech creates. Founded in 2017, Codeash
                              Infotech is a leading web and app development
                              company that specializes in creating innovative
                              and user-friendly digital solutions. Our passion
                              is to harness the power of technology to help our
                              clients achieve their business goals.
                            </p>
                            <p className="fs_19 mt-3">
                              What sets Codeash Infotech apart?
                            </p>
                          </div>
                        </Fade>
                        <div className="inner-box p_relative d_block wow fadeInUp pt_20">
                          <Slide left>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="CHECK_MARK"
                                    className="w_30"
                                  />
                                </div>
                              </div>
                              <h4 className="d_block fs_20 lh_30 mb_4">
                                Our team of experienced and skilled developers
                              </h4>
                            </div>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_3 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="CHECK_MARK"
                                    className="w_30"
                                  />
                                </div>
                              </div>
                              <h4 className="d_block fs_20 lh_30 mb_4">
                                Our expertise in a wide range of technologies
                              </h4>
                            </div>
                            <div className="single-item p_relative d_block pl_55 mb_16">
                              <div className="icon-box p_absolute l_0 t_3 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="CHECK_MARK"
                                    className="w_30"
                                  />
                                </div>
                              </div>
                              <h4 className="d_block fs_20 lh_30 mb_4">
                                Our commitment to innovation and creativity
                              </h4>
                            </div>
                            <div className="single-item p_relative d_block pl_55">
                              <div className="icon-box p_absolute l_0 t_3 w_50 h_50 text-center">
                                <div className="icon p_relative d_iblock g_color">
                                  <img
                                    src={"/assets/images/img/arrow-right.png"}
                                    alt="CHECK_MARK"
                                    className="w_30"
                                  />
                                </div>
                              </div>
                              <h4 className="d_block fs_20 lh_30 mb_4">
                                Our commitment to customer service and support
                              </h4>
                            </div>
                          </Slide>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Fade right>
                    <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                      <div className="image_block_two">
                        <div className="p_relative d_block mt_20">
                          <figure className="image p_relative d_block">
                            <img
                              src={"/assets/images/img/about_our_company.svg"}
                              alt="ABOUT_OUR_COMPANY"
                              className="company_img"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </Fade>
                </div>
                <div className="row align-items-center clearfix mb_30">
                  <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                    <div className="">
                      <div className="p_relative d_block pt_100">
                        <figure className="image p_relative d_block">
                          <img
                            src={"/assets/images/img/why_choose_us.svg"}
                            alt="WHY_CHOOSE_US"
                            className="company_img1"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                    <div className="mt-5 pt_50">
                      <div className="content-box p_relative d_block  mt-5">
                        <Fade right>
                          <div className="sec-title p_relative d_block mb_30">
                            <h2 className="p_relative d_block fs_40 fw_bold mt-1">
                              Why Choose Us?
                            </h2>
                          </div>
                        </Fade>
                        <Fade right>
                          <div
                            className="text p_relative d_block mb_30 wow fadeInUp"
                            data-wow-duration="1500ms"
                          >
                            <p className="fs_18">
                              At Codeash Infotech, we're committed to delivering
                              quality mobile and web solutions that help you
                              stand out from the competition with our passion
                              for creativity, innovation, and user-centered
                              design.
                            </p>
                          </div>
                        </Fade>
                        <ul
                          className="list-style-one clearfix p_relative d_block mb_30 wow fadeInUp"
                          data-wow-duration="1500ms"
                        >
                          <li className="p_relative d_block fs_20 lh_50 fw_sbold pull-left pl_19 pr_50 mb_20">
                            <Fade top>Transparency :</Fade>
                            <Fade bottom>
                              <p className="lh_25">
                                Complete Transparent services so you know
                                exactly what you're paying for.
                              </p>
                            </Fade>
                          </li>
                          <li className="p_relative d_block fs_20 lh_50 fw_sbold pull-left pl_19 pr_50 mb_20">
                            <Fade top>Affordable :</Fade>

                            <Fade bottom>
                              <p className="lh_25">
                                Budget-friendly software development that won't
                                break the bank.
                              </p>
                            </Fade>
                          </li>
                          <li className="p_relative d_block fs_20 lh_50 fw_sbold pull-left pl_19 pr_50 mb_20">
                            <Fade top>Reliable :</Fade>
                            <Fade bottom>
                              <p className="lh_25">
                                Daily meetings to keep your project on time and
                                within budget.
                              </p>
                            </Fade>
                          </li>
                          <li className="p_relative d_block fs_20 lh_50 fw_sbold pull-left pl_19 pr_50 mb_20">
                            <Fade top>
                            Agile :
                            </Fade>
                            <Fade bottom>
                            <p className="lh_25">
                              Trusted software development experts with a proven
                              track record.
                            </p>
                            </Fade>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Phone Content----------------- */}
        <div className="phone_Content">
          <div className="p_relative pb_10 mb_10">
            <div className="auto-container">
              <div className="inner-container p_relative">
                <div className="row align-items-center clearfix mb_30 mt-5">
                  <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                    <div className="mt_40 pt_40">
                      <div className="content-box p_relative d_block ml_30">
                        <div className="sec-title p_relative d_block mb_20">
                          <h2 className="p_relative d_block fs_40 fw_bold mt-1">
                            About our company
                          </h2>
                        </div>
                        <div
                          className="text p_relative d_block mb_25 wow fadeInUp"
                          data-wow-duration="1500ms"
                        >
                          <p className="fs_19">
                            Imagine a world where your business runs smoothly
                            and efficiently, with software and applications
                            tailored to your specific needs. That's the world
                            Codeash Infotech creates. Founded in 2017, Codeash
                            Infotech is a leading web and app development
                            company that specializes in creating innovative and
                            user-friendly digital solutions. Our passion is to
                            harness the power of technology to help our clients
                            achieve their business goals.
                          </p>
                        </div>
                        <div
                          className="inner-box p_relative d_block wow fadeInUp pt_20"
                          data-wow-duration="1500ms"
                        >
                          <div className="single-item p_relative d_block pl_55 mb_16">
                            <div className="icon-box p_absolute l_0 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"/assets/images/img/arrow-right.png"}
                                  alt="CHECK_MARK"
                                  className="w_30"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4">
                              Our team of experienced and skilled developers
                            </h4>
                          </div>
                          <div className="single-item p_relative d_block pl_55 mb_16">
                            <div className="icon-box p_absolute l_0 t_3 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"/assets/images/img/arrow-right.png"}
                                  alt="CHECK_MARK"
                                  className="w_30"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4">
                              Our expertise in a wide range of technologies
                            </h4>
                          </div>
                          <div className="single-item p_relative d_block pl_55 mb_16">
                            <div className="icon-box p_absolute l_0 t_3 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"/assets/images/img/arrow-right.png"}
                                  alt="CHECK_MARK"
                                  className="w_30"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4">
                              Our commitment to innovation and creativity
                            </h4>
                          </div>
                          <div className="single-item p_relative d_block pl_55">
                            <div className="icon-box p_absolute l_0 t_3 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"/assets/images/img/arrow-right.png"}
                                  alt="CHECK_MARK"
                                  className="w_30"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4">
                              Our commitment to customer service and support
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                    <div className="">
                      <div className="p_relative d_block pt_100">
                        <figure className="image p_relative d_block">
                          <img
                            src={"/assets/images/img/about_our_company.svg"}
                            alt="WHY_CHOOSE_US"
                            className="company_img1 mb-5"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center clearfix mb_30 mt-5">
                  {/* <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                    <div className="mt_40 pt_40">
                      <div className="content-box p_relative d_block ml_30">
                        <div className="sec-title p_relative d_block mb_20">
                          <h2 className="p_relative d_block fs_40 fw_bold mt-1">
                            Why Choose Us?
                          </h2>
                        </div>
                        <div
                          className="text p_relative d_block mb_25 wow fadeInUp"
                          data-wow-duration="1500ms"
                        >
                          <p className="fs_19">
                            At Codeash Infotech, we're committed to delivering
                            quality mobile and web solutions that help you stand
                            out from the competition with our passion for
                            creativity, innovation, and user-centered design.
                          </p>
                        </div>
                        <div
                          className="inner-box p_relative d_block wow fadeInUp pt_20"
                          data-wow-duration="1500ms"
                        >
                          <div className="single-item p_relative d_block pl_55 mb_16">
                            <div className="icon-box p_absolute l_0 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"assets/images/img/arrow-right.png"}
                                  alt="CHECK_MARK"
                                  className="w_30"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4">
                              Budget-friendly software development that won't
                              break the bank.
                            </h4>
                          </div>
                          <div className="single-item p_relative d_block pl_55 mb_16">
                            <div className="icon-box p_absolute l_0 t_3 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"assets/images/img/arrow-right.png"}
                                  alt="CHECK_MARK"
                                  className="w_30"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4">
                              Trusted software development partners who always
                              have your back.
                            </h4>
                          </div>
                          <div className="single-item p_relative d_block pl_55 mb_16">
                            <div className="icon-box p_absolute l_0 t_3 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"assets/images/img/arrow-right.png"}
                                  alt="CHECK_MARK"
                                  className="w_30"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4">
                              Daily meetings to keep your project on time and
                              within budget.
                            </h4>
                          </div>
                          <div className="single-item p_relative d_block pl_55 mb_16">
                            <div className="icon-box p_absolute l_0 t_3 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"assets/images/img/arrow-right.png"}
                                  alt="CHECK_MARK"
                                  className="w_30"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4">
                              Trusted software development experts with a proven
                              track record.
                            </h4>
                          </div>
                          <div className="single-item p_relative d_block pl_55">
                            <div className="icon-box p_absolute l_0 t_3 w_50 h_50 text-center">
                              <div className="icon p_relative d_iblock g_color">
                                <img
                                  src={"assets/images/img/arrow-right.png"}
                                  alt="CHECK_MARK"
                                  className="w_30"
                                />
                              </div>
                            </div>
                            <h4 className="d_block fs_20 lh_30 mb_4">
                              Transparent software development services so you
                              know exactly what you're paying for
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                    <div className="mt_40 pt_40">
                      <div className="content-box p_relative d_block ml_30">
                        <div className="sec-title p_relative d_block mb_20">
                          <h2 className="p_relative d_block fs_40 fw_bold mt-1">
                            Why Choose Us?
                          </h2>
                        </div>
                        <div
                          className="text p_relative d_block mb_25 wow fadeInUp"
                          data-wow-duration="1500ms"
                        >
                          <p className="fs_19">
                            At Codeash Infotech, we're committed to delivering
                            quality mobile and web solutions that help you stand
                            out from the competition with our passion for
                            creativity, innovation, and user-centered design.
                          </p>
                        </div>
                        <ul
                          className="list-style-one clearfix p_relative d_block mb_30 wow fadeInUp"
                          data-wow-duration="1500ms"
                        >
                          <li className="p_relative d_block fs_20 lh_60 fw_sbold pull-left pl_19 mb_10">
                            Transparency :
                            <p>
                              Complete Transparent services so you know exactly
                              what you're paying for.
                            </p>
                          </li>
                          <li className="p_relative d_block fs_20 lh_60 fw_sbold pull-left pl_19 mb_10">
                            Affordable :
                            <p>
                              Budget-friendly software development that won't
                              break the bank.
                            </p>
                          </li>
                          <li className="p_relative d_block fs_20 lh_60 fw_sbold pull-left pl_19 mb_10">
                            Reliable :
                            <p>
                              Daily meetings to keep your project on time and
                              within budget.
                            </p>
                          </li>
                          <li className="p_relative d_block fs_20 lh_60 fw_sbold pull-left pl_19">
                            Agile :
                            <p>
                              Trusted software development experts with a proven
                              track record.
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                    <div className="">
                      <div className="p_relative d_block pt_100">
                        <figure className="image p_relative d_block">
                          <img
                            src={"/assets/images/img/why_choose_us.svg"}
                            alt="WHY_CHOOSE_US"
                            className="company_img1 mb-5"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ClientLogo />
      </div>
    </>
  );
};
export default About;
