import React from "react";
import { useContext } from "react";
import { ConfigurationContext } from "../context/CreateContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Maintenance = ({ title }) => {
  const { configurationData, getAllConfiguration } =
    useContext(ConfigurationContext);
  const navigate = useNavigate();
  let cname;

  if (configurationData) {
    cname = `${configurationData.cname}`;
    // console.log(configurationData);
    if (configurationData.mmode === "false") {
      navigate("/");
    }
  } else {
    cname = `${process.env.REACT_APP_COMPANY_SITE}`;
  }
  useEffect(() => {
    document.title = `${title} | ${cname} `;
    getAllConfiguration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // width: 70%;
  //   margin: auto;
  //   padding: 53px;
  //   background: rgba(255,255,255,0.25);
  //   -webkit-backdrop-filter: blur(20px);
  //   backdrop-filter: blur(20px);
  //   /* color: red;
  return (
    <>
      <section className="comingsoon-section centred">
        <div className="auto-container">
          <div
            className=""
            style={{
              width: "70%",
              margin: "auto",
              padding: "100px 66px",
              backgroundColor: "rgba(255,255,255,0.25)",
              WebkitBackdropFilter : "blur(20px)",
              backdropFilter : "blur(20px)"
            }}
          >
            <div className="content-box">
              <h2 className="d_block fs_70 lh_70 fw_exbold mb_15 " style={{ color: "black" }}>
                We’re Coming Soon
              </h2>
              <h3 className="d_block fs_34 lh_30 fw_medium mb_65" style={{color : "#24b2c5"}}>
                We will be back soon. Stay tuned!
              </h3>
              <div className="timer p_relative d_block mb_60">
                <div
                  className="cs-countdown"
                  data-countdown="01/24/2022 05:06:59"
                />
              </div>
              {/* <div className="row border">
              <div className="col-4">Email</div>
              <div className="col-4">Admin Number</div>
              <div className="col-4">Hr Number</div>
            </div> */}
            </div>
            <div className="row clearfix ">
              <div className="col-lg-6 col-md-6 col-sm-12 single-column">
                <div className="single-item p_relative d_block">
                  <h3 className="d_block fs_24 lh_30 fw_bold font_family_poppins mb_6 ">
                    HR Number
                  </h3>
                  <p
                    className="mb_0 fs_16 font_family_poppins text-dark"
                    style={{ color: "black" }}
                  >
                    8511476162
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 single-column">
                <div className="single-item p_relative d_block ">
                  <h3 className="d_block fs_24 lh_30 fw_bold font_family_poppins  mb_6 ">
                    HR Email
                  </h3>
                  <p
                    className="mb_0 fs_16 font_family_poppins text-dark"
                    style={{ color: "black" }}
                  >
                    hr@codeashinfotech.com
                  </p>
                </div>
              </div>
              {/* <div className="col-lg-4 col-md-6 col-sm-12 single-column">
                <div className="single-item p_relative d_block ">
                  <h3 className="d_block fs_24 lh_30 fw_bold  mb_6 font_family_poppins">
                    Admin Email
                  </h3>
                  <p
                    className="mb_0 fs_16 font_family_poppins text-dark"
                    style={{ color: "red" }}
                  >
                    admin@codeashinfotech.com
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Maintenance;
